import React from "react";
import "./footer.scss";
import logo from "../../assets/img/logo_white.png";
import { Fade, Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import GlobalContainer from "../../components/GlobalContainer/GlobalContainer";

export default function Footer() {
  return (
    <div className="footer" id="footer">
      <GlobalContainer>
        <div className="footer_content">
          <Link to="/" className="footer_logo">
            <Fade left duration={2000}>
              <img src={logo} alt="logo" />
            </Fade>
          </Link>

          <div className="footer_content-block">
            <Fade top duration={2000}>
              <h6>Contacts</h6>
            </Fade>
            <Zoom duration={2000} cascade>
              <ul className="footer-dates">
                <li>
                  <a href="mailto:undigitals@gmail.com">
                    Email: undigitals@gmail.com{" "}
                  </a>
                </li>
                <li>Phone: +998 (99) 398 24 42</li>
              </ul>
            </Zoom>
          </div>

          <div className="footer_content-block block_address">
            <Fade right duration={2000}>
              <h6>Address</h6>
            </Fade>
            <Zoom duration={2000}>
              <p>Termiz, Uzbekistan</p>
            </Zoom>
          </div>
        </div>

        <div className="footer_socials">
          <Fade top duration={2000} cascade>
            <ul className="footer_socials-list">
              <li>
                <a
                  href="https://www.linkedin.com/in/umid-negmatullayev-8279b9148/"
                  target="_blank"
                >
                  <i className="bx bxl-linkedin"></i>
                </a>
              </li>

              {/* <li>
                <a href="#!">
                  <i className="bx bxl-facebook"></i>
                </a>
              </li> */}
              <li>
                <a
                  href="https://youtube.com/@umidnegmatullayev3318"
                  target="_blank"
                >
                  <i className="bx bxl-youtube"></i>
                </a>
              </li>
              {/* <li>
                <a href="#!">
                  <i className="bx bxl-instagram"></i>
                </a>
              </li> */}
              <li>
                <a href="https://github.com/undigitals" target="_blank">
                  <i class="bx bxl-github"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/undigitals" target="_blank">
                  <i className="bx bxl-twitter"></i>
                </a>
              </li>
            </ul>
          </Fade>
        </div>
      </GlobalContainer>
    </div>
  );
}

{
  /* <ul>
<li><a href="#">Home</a></li>
<li><a href="#">Blog</a></li>
<li><a href="#">Contact</a></li>
</ul> */
}
