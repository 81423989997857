import teduLogo from "../../../assets/img/teduLogo.png";
import rapidiligence from "../../../assets/img/rapidDelegence.png";
import iamfuture from "../../../assets/img/iamfuture.png";
import wavemLogo from "../../../assets/img/wavemLogo.png";

export const testimonals = [
  {
    id: 1,
    name: "Ahmed",
    title: "Rapid Diligence",
    src: "",
    img: "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    logo: rapidiligence,
    companyLogo: "",
    desc: "Umid was an exceptional React developer and built out the application we were looking for precisely as expected. He's a very talented developer, communicates effectively, and overall did a stellar job. I plan on working with Umid again for future projects!",
  },
  {
    id: 2,
    name: "Wave",
    title: "WAVEM",
    src: "",
    img: "https://images.pexels.com/photos/428321/pexels-photo-428321.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    logo: wavemLogo,
    desc: "Working with this developer has been an absolute pleasure from start to finish. Their communication skills were exceptional, and they kept me updated on the progress of the project every step of the way.",
    featured: true,
  },
  {
    id: 3,
    name: "Nadya",
    title: "I AM FUTURE",
    src: "https://www.linkedin.com/",
    img: "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    logo: iamfuture,
    desc: "Umid is an awesome developer",
  },
  {
    id: 4,
    name: "Max",
    title: "TEDU",
    src: "https://www.linkedin.com/",
    img: "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    logo: teduLogo,
    desc: "I had the pleasure of working with Umid at TEDU Inc., where he undertook a project of rebuilding our native mobile apps in React Native. Umid is a hard-working and result-oriented developer, willing to take on any task regardless of complexity ...",
  },
];
